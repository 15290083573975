import { cn, getUserImgSrc } from '~/utils/misc.tsx'
import { ScrollArea } from '../ui/scroll-area.tsx'
import { type Video } from '@prisma/client'
import { Link, useLocation } from '@remix-run/react'
import { StarsRating } from '../ui/stars.tsx'
import { getServicesStars } from '~/features/reviews/getStarsAndReviewDataForProfesional.tsx'

export const VideoCard = ({
  video,
  className,
  withImage,
}: {
  video: Video & {
    user?: {
      services: any
      slug: string
      nickname: string
      avatarImage: {
        id: string
      } | null
    }
  }
  className?: string
  withImage?: boolean
}) => {
  const location = useLocation()
  const query = `?from=${location.pathname
    .split('?filter')[0]
    .replace('/', '')}${
    location.search.length > 0 ? `&${location.search.replace('?', '')}` : ''
  }`
  const stars =
    withImage && video.user ? getServicesStars(video.user.services) : null
  return (
    <div
      className={cn(
        'flex w-full flex-col gap-2 rounded-sm border border-gray-300 p-3',
        className,
      )}
    >
      <iframe
        width="100%"
        height="215"
        src={video.url}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <h3 className="text-h6 text-sm">{video.title}</h3>
      <ScrollArea className="h-[60px]">
        <p className="mt-1 text-sm text-foreground-muted">
          {video.description}
        </p>
      </ScrollArea>
      {withImage && video.user && (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Link to={`/${video.user.slug}${query}`}>
              <img
                className="mx-auto h-[56px] w-[56px] rounded-full object-cover"
                alt={String(video.user.nickname)}
                src={getUserImgSrc(video.user.avatarImage?.id)}
              />
            </Link>
            <p className="max-w-full text-wrap text-center text-h6">
              {video.user.nickname}
            </p>
          </div>
          <Link to={`/${video.user.slug}${query}&stars`}>
            <StarsRating value={stars ?? 0} />
          </Link>
        </div>
      )}
    </div>
  )
}
